<template>
  <main>
    <trac-back-button>
      Back
    </trac-back-button>
    <p class="  text-gray-800 mt-5 ml-3 font-medium ">
      Complete KYC by verifying <br />the following.
    </p>
    <div class="  w-full bg-white rounded-md mt-12 big-shadow p-5 ">
      <div class="flex justify-center flex-col items-center mt-5">
        <svg
          width="90"
          height="90"
          viewBox="0 0 90 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 11.2127C0 5.02008 5.02009 0 11.2127 0H78.4889C84.6815 0 89.7016 5.02009 89.7016 11.2127V78.4889C89.7016 84.6815 84.6815 89.7016 78.4889 89.7016H11.2127C5.02008 89.7016 0 84.6815 0 78.4889V11.2127Z"
            fill="#EFFAFF"
          />
          <g clip-path="url(#clip0)">
            <path
              d="M64.2707 64.8672H25.7293C22.5688 64.8672 20 62.2984 20 59.1379V33.0961C20 29.9356 22.5688 27.3672 25.7293 27.3672H38.2293C39.0918 27.3672 39.7918 28.0672 39.7918 28.9297C39.7918 29.7922 39.0918 30.4922 38.2293 30.4922H25.7293C24.2938 30.4922 23.125 31.6606 23.125 33.0961V59.1379C23.125 60.5734 24.2938 61.7422 25.7293 61.7422H64.2707C65.7062 61.7422 66.875 60.5734 66.875 59.1379V33.0961C66.875 31.6606 65.7062 30.4922 64.2707 30.4922H51.7707C50.9082 30.4922 50.2082 29.7922 50.2082 28.9297C50.2082 28.0672 50.9082 27.3672 51.7707 27.3672H64.2707C67.4312 27.3672 70 29.9356 70 33.0961V59.1379C70 62.2984 67.4312 64.8672 64.2707 64.8672Z"
              fill="#253B95"
            />
            <path
              d="M51.7707 39.8672H40.3125C39.45 39.8672 38.75 39.1672 38.75 38.3047C38.75 37.4422 39.45 36.7422 40.3125 36.7422H51.7707C52.6332 36.7422 53.3332 37.4422 53.3332 38.3047C53.3332 39.1672 52.6332 39.8672 51.7707 39.8672Z"
              fill="#253B95"
            />
            <path
              d="M49.6887 31.5312H38.2305C37.368 31.5312 36.668 30.8313 36.668 29.9687C36.668 29.1062 37.368 28.4062 38.2305 28.4062H49.6887C50.5512 28.4062 51.2512 29.1062 51.2512 29.9687C51.2512 30.8313 50.5512 31.5312 49.6887 31.5312Z"
              fill="#253B95"
            />
            <path
              d="M51.7707 48.1992H40.3125C39.45 48.1992 38.75 47.4992 38.75 46.6367C38.75 45.7742 39.45 45.0742 40.3125 45.0742H51.7707C52.6332 45.0742 53.3332 45.7742 53.3332 46.6367C53.3332 47.4992 52.6332 48.1992 51.7707 48.1992Z"
              fill="#253B95"
            />
            <path
              d="M51.7707 56.5312H40.3125C39.45 56.5312 38.75 55.8313 38.75 54.9688C38.75 54.1062 39.45 53.4062 40.3125 53.4062H51.7707C52.6332 53.4062 53.3332 54.1062 53.3332 54.9688C53.3332 55.8313 52.6332 56.5312 51.7707 56.5312Z"
              fill="#253B95"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="50"
                height="50"
                fill="white"
                transform="translate(20 19)"
              />
            </clipPath>
          </defs>
        </svg>

        <h1 class="mt-6 font-bold text-primaryBlue text-xl">
          Verify BVN details
        </h1>
        <p class="mt-3">Hi {{ name }}, kindly verify your BVN details.</p>
        <div class=" mx-auto         w-128 mt-5">
          <trac-input
            placeholder="Bvn "
            v-model="bvn"
            class="mt-5"
            type="number"
          ></trac-input>
          <!-- <trac-input
            placeholder="First Name"
            v-model="firstName"
            class="mt-5"
          ></trac-input>
          <trac-input
            placeholder="Last Name"
            v-model="lastName"
            class="mt-5"
          ></trac-input>
          <trac-input
            placeholder="Phone Numbers"
            v-model="phoneNumber"
            class="mt-5"
            type="number"
          ></trac-input> -->
          <trac-input
            placeholder="Date of Birth"
            v-model="dob"
            class="mt-5"
            type="date"
          ></trac-input>
        </div>
        <div class=" w-128 mx-auto text-right my-10">
          <trac-button :disabled="loading" @click.native="launchLiveness">
            <div class=" px-8">
              Submit
            </div>
          </trac-button>
        </div>
      </div>

      <!-- <div class="shadow-md rounded pl-10 pr-10 pt-10 pb-10 ml-12 mr-12 mt-10">
      <img
        src="./../../assets/svg/terminal.svg"
        alt=""
        class="block m-auto mb-8"
      />
      <trac-button class="m-auto block" @button-clicked="gotoTermianals"
        >Terminals</trac-button
      >
    </div> -->
    </div>
    <trac-modal v-if="modalInfo" @close="modalInfo = false">
      <div class="p-4">
        <h1 class=" font-medium mt-5">Why do we need your BVN?</h1>
        <div class="flex items-center mt-6">
          <svg
            class="w-5 h-5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 5.9C12.2758 5.9 12.5489 5.95432 12.8036 6.05985C13.0584 6.16539 13.2899 6.32007 13.4849 6.51508C13.6799 6.71008 13.8346 6.94158 13.9401 7.19636C14.0457 7.45115 14.1 7.72422 14.1 8C14.1 8.27578 14.0457 8.54885 13.9401 8.80364C13.8346 9.05842 13.6799 9.28992 13.4849 9.48492C13.2899 9.67993 13.0584 9.83461 12.8036 9.94015C12.5489 10.0457 12.2758 10.1 12 10.1C11.443 10.1 10.9089 9.87875 10.5151 9.48492C10.1212 9.0911 9.9 8.55695 9.9 8C9.9 7.44305 10.1212 6.9089 10.5151 6.51508C10.9089 6.12125 11.443 5.9 12 5.9ZM12 14.9C14.97 14.9 18.1 16.36 18.1 17V18.1H5.9V17C5.9 16.36 9.03 14.9 12 14.9ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 13C9.33 13 4 14.34 4 17V20H20V17C20 14.34 14.67 13 12 13Z"
              fill="#003283"
              fill-opacity="0.51"
            />
          </svg>
          <p class="ml-3 text-sm">
            Your BVN confirms your identity
          </p>
        </div>
        <div class="flex  mt-5">
          <svg
            class="w-5 h-5 mt-1"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.4343 17.3252L3.0803 19.8002C3.05842 19.9539 3.0726 20.1105 3.1217 20.2577C3.1708 20.4049 3.25348 20.5386 3.36321 20.6483C3.47293 20.7581 3.60668 20.8407 3.75388 20.8898C3.90108 20.9389 4.05768 20.9531 4.2113 20.9312L6.6863 20.5772C7.0613 20.5242 8.0013 18.0002 8.0013 18.0002C8.0013 18.0002 8.4733 18.4052 8.6663 18.4662C9.0783 18.5962 9.4793 18.1922 9.6143 17.7822L10.0013 16.0102C10.0013 16.0102 10.5783 16.3022 10.7873 16.3452C11.0533 16.4002 11.3113 16.2362 11.4943 16.0522C11.6042 15.9427 11.6868 15.8087 11.7353 15.6612L12.0013 14.0102C12.0013 14.0102 12.6763 14.1972 12.9073 14.2242C13.1703 14.2542 13.4263 14.1202 13.6143 13.9312L14.7523 12.7942C15.7156 13.1064 16.7464 13.1467 17.7311 12.9106C18.7158 12.6745 19.6163 12.1712 20.3333 11.4562C21.363 10.4239 21.9412 9.02532 21.9412 7.56724C21.9412 6.10916 21.363 4.7106 20.3333 3.67824C19.301 2.64856 17.9024 2.07031 16.4443 2.07031C14.9862 2.07031 13.5877 2.64856 12.5553 3.67824C11.8401 4.39517 11.3367 5.29563 11.1006 6.28039C10.8645 7.26515 10.9049 8.29597 11.2173 9.25924L3.7163 16.7592C3.56319 16.9122 3.46418 17.1109 3.4343 17.3252ZM18.5053 5.50624C19.0509 6.05341 19.3572 6.79457 19.3572 7.56724C19.3572 8.33992 19.0509 9.08108 18.5053 9.62825L14.3833 5.50624C14.9305 4.96069 15.6716 4.65433 16.4443 4.65433C17.217 4.65433 17.9581 4.96069 18.5053 5.50624Z"
              fill="#003283"
              fill-opacity="0.51"
            />
          </svg>

          <p class="ml-3 text-sm">
            Your BVN ensures that your account is protected from unauthorized
            access access.
          </p>
        </div>
        <div class="flex  mt-5 pb-6">
          <svg
            class="w-5 h-5  mt-px"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 5C0 4.33696 0.263392 3.70107 0.732233 3.23223C1.20107 2.76339 1.83696 2.5 2.5 2.5H17.5C18.163 2.5 18.7989 2.76339 19.2678 3.23223C19.7366 3.70107 20 4.33696 20 5V15C20 15.663 19.7366 16.2989 19.2678 16.7678C18.7989 17.2366 18.163 17.5 17.5 17.5H2.5C1.83696 17.5 1.20107 17.2366 0.732233 16.7678C0.263392 16.2989 0 15.663 0 15V5ZM2.5 3.75C2.16848 3.75 1.85054 3.8817 1.61612 4.11612C1.3817 4.35054 1.25 4.66848 1.25 5V6.25H18.75V5C18.75 4.66848 18.6183 4.35054 18.3839 4.11612C18.1495 3.8817 17.8315 3.75 17.5 3.75H2.5ZM18.75 8.75H1.25V15C1.25 15.3315 1.3817 15.6495 1.61612 15.8839C1.85054 16.1183 2.16848 16.25 2.5 16.25H17.5C17.8315 16.25 18.1495 16.1183 18.3839 15.8839C18.6183 15.6495 18.75 15.3315 18.75 15V8.75Z"
              fill="#003283"
              fill-opacity="0.51"
            />
            <path
              d="M2.5 12.5C2.5 12.1685 2.6317 11.8505 2.86612 11.6161C3.10054 11.3817 3.41848 11.25 3.75 11.25H5C5.33152 11.25 5.64946 11.3817 5.88388 11.6161C6.1183 11.8505 6.25 12.1685 6.25 12.5V13.75C6.25 14.0815 6.1183 14.3995 5.88388 14.6339C5.64946 14.8683 5.33152 15 5 15H3.75C3.41848 15 3.10054 14.8683 2.86612 14.6339C2.6317 14.3995 2.5 14.0815 2.5 13.75V12.5Z"
              fill="#003283"
              fill-opacity="0.51"
            />
          </svg>

          <p class="ml-3 text-sm">
            Your BVN allows you to track all your transactions <br />
            in any bank.
          </p>
        </div>
      </div>
    </trac-modal>
    <trac-modal @close="error = false" v-if="error">
      <div class="flex flex-col justify-center items-center">
        <svg
          class="w-16 h-16 mt-20"
          viewBox="0 0 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="44" cy="44" r="42" stroke="#CE1112" stroke-width="4" />
          <path
            d="M64 24L24 64"
            stroke="#CE1112"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24 24L64 64"
            stroke="#CE1112"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="text-center tracking-normal text-sm mt-8">
          BVN not found. Please check BVN <br />
          number and try again
        </p>
        <trac-button
          @click.native="verifyModal = false"
          class="w-32 mt-8 mb-12"
          type="submit"
          ><span class="px-12  text-center">Ok</span></trac-button
        >
      </div>
    </trac-modal>
    <trac-modal v-if="successModal" :cancel="false">
      <div class="flex flex-col justify-center items-center">
        <svg
          class="w-16 h-16 mt-20"
          viewBox="0 0 170 157"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="81.4572"
            cy="80.1007"
            r="43.2554"
            fill="#11A529"
            fill-opacity="0.12"
            stroke="#B2E7FF"
            stroke-width="1.33329"
          />
          <path
            d="M60.3086 81.1842L68.4863 91.4149C69.5953 92.8022 71.6337 92.9931 72.981 91.8358L103.688 65.459"
            stroke="#253B95"
            stroke-width="5.33316"
          />
          <path
            d="M30.1113 29.4553C30.8623 32.1926 37.0518 37.7793 36.9784 28.2139C36.905 18.6486 40.7531 20.6235 44.679 22.2492"
            stroke="#0143AA"
            stroke-width="1.00759"
          />
          <path
            d="M78.4115 18.3942C80.8891 21.0813 91.2728 23.8118 85.5327 12.9136C79.7925 2.01542 85.3621 1.99895 90.8141 1.53712"
            stroke="#0143AA"
            stroke-width="1.29746"
          />
          <path
            d="M19.2072 98.4257C15.6305 95.1111 1.53402 92.5668 10.3098 106.461C19.0855 120.355 11.6796 120.96 4.47651 122.145"
            stroke="#0143AA"
            stroke-width="1.731"
          />
          <path
            d="M151.657 100.832C146.822 101.466 135.86 110.687 152.156 112.81C168.451 114.933 164.186 121.018 160.497 127.317"
            stroke="#B2E7FF"
            stroke-width="1.731"
          />
          <circle cx="64.5282" cy="137.579" r="3.79573" fill="#253B95" />
          <circle cx="87.8434" cy="148.578" r="7.59146" fill="#B2E7FF" />
          <circle cx="28.7391" cy="52.9891" r="3.79573" fill="#06B6CB" />
          <circle cx="142.612" cy="66.0033" r="7.04922" fill="#06B6CB" />
          <path
            d="M117.125 17.4727L119.468 23.8053L125.801 26.1486L119.468 28.4919L117.125 34.8246L114.782 28.4919L108.449 26.1486L114.782 23.8053L117.125 17.4727Z"
            fill="#06B6CB"
          />
          <path
            d="M4.88023 49.1934L6.19833 52.7555L9.76045 54.0736L6.19833 55.3917L4.88023 58.9538L3.56212 55.3917L0 54.0736L3.56212 52.7555L4.88023 49.1934Z"
            fill="#06B6CB"
          />
          <path
            d="M126.343 115.891L128.833 122.619L135.561 125.109L128.833 127.599L126.343 134.327L123.853 127.599L117.125 125.109L123.853 122.619L126.343 115.891Z"
            fill="#013E9E"
          />
          <path
            d="M31.8258 132.781L34.9922 136.864L40.1526 137.118L36.0699 140.284L35.8161 145.445L32.6497 141.362L27.4893 141.108L31.572 137.942L31.8258 132.781Z"
            fill="#013E9E"
          />
        </svg>

        <p class="text-center tracking-normal text-sm mt-8">
          Your documents have been uploaded and will be verified shortly.
        </p>
        <trac-button
          @click.native="$router.push({ name: 'Kyc' })"
          class="w-32 mt-8 mb-6"
          type="submit"
          ><span class="px-12  text-center uppercase"
            >Continue Verification</span
          ></trac-button
        >
      </div>
    </trac-modal>
    <trac-modal @close="failureModal = false" v-if="failureModal">
      <div class="flex flex-col justify-center items-center">
        <svg
          class="w-16 h-16 mt-20"
          viewBox="0 0 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="44" cy="44" r="42" stroke="#CE1112" stroke-width="4" />
          <path
            d="M64 24L24 64"
            stroke="#CE1112"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24 24L64 64"
            stroke="#CE1112"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="text-center tracking-normal text-sm mt-8 pb-12">
          There was a problem verifying your document.
        </p>
      </div>
    </trac-modal>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  ITERATEALL_ALL_AUTH_USERS,
} from "../../browser-db-config/localStorage";
import moment from "moment";
import { eventBus } from "../../main";

export default {
  data() {
    return {
      moment,
      firstName: "",
      lastName: "",
      bvn: "",
      name: "",
      dob: "",
      modalInfo: true,
      error: false,
      successModal: false,
      failureModal: false,
      loading: false,
    };
  },
  methods: {
    async launchLiveness() {
      if (this.bvn === "") {
        eventBus.$emit("trac-alert", {
          message: "Enter Bvn Number",
        });
        return;
      }
      if (this.dob === "") {
        eventBus.$emit("trac-alert", {
          message: "Enter Date of Birth",
        });
        return;
      }

      let payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        idNumber: this.bvn,
        idType: "bvn",
        selfieUrl: this.$store.state.miscellaneous.kyc.pic,
        documentImageUrl: "",
        dob: this.moment(this.dob).format("YYYY/MM/DD"),
      };
      this.loading = true;
      let response = await this.$store.dispatch("VERIFY_DOCUMENT", payload);
      this.loading = false;
      if (response.status) {
        this.successModal = true;
        this.$store.commit("UPDATE_KYC", "");
      } else {
        this.failureModal = true;
      }
    },
  },
  mounted() {
    if (ITERATEALL_ALL_AUTH_USERS() && ITERATEALL_ALL_AUTH_USERS().length) {
      this.firstName =
        ITERATEALL_ALL_AUTH_USERS()[0].user.name.split(" ")[0] || "";
      this.lastName =
        ITERATEALL_ALL_AUTH_USERS()[0].user.name.split(" ")[1] || "";
    }

    this.name = GET_USER_BUSINESS_DATA().name;
  },
};
</script>

<style></style>
